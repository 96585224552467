import { Button, Input } from 'antd'
import Styles from './SignInPage.module.scss'
import Logo from 'src/assets/logo.png'
import { UserOutlined, LockFilled } from '@ant-design/icons'
import Map from 'react-map-gl'
import maplibregl from 'maplibre-gl'
import { useViewModel } from './SignInPage.vm'
import { MapConfig, MapStyleJson } from 'src/meta'

export const SignInPage = () => {
  const { password, username, setPassword, setUsername, signIn } = useViewModel()
  return (
    <div className={Styles.page}>
      <div className={Styles.map}>
        <Map
          mapLib={maplibregl as any}
          initialViewState={MapConfig}
          scrollZoom={false}
          dragPan={false}
          style={{ width: '100vw', height: '100vh' }}
          mapStyle={MapStyleJson.Topo}
          interactive={false}
          attributionControl={false}
        ></Map>
      </div>
      <div className={Styles.inputs}>
        <img src={Logo} />
        <h1>Integrated urban weather monitoring and data-sharing platform for smart cities</h1>
        <Input
          placeholder="Account"
          suffix={<UserOutlined />}
          value={username}
          onChange={e => setUsername(e.currentTarget.value)}
        />
        <Input
          placeholder="Password"
          type="password"
          suffix={<LockFilled />}
          value={password}
          onChange={e => setPassword(e.currentTarget.value)}
        />
        <Button className={Styles.btnSignIn} type="primary" onClick={signIn}>
          Sign In
        </Button>
      </div>
    </div>
  )
}
