import { config } from "./config"

export const getToken = () => {
  const token = localStorage.getItem('token') ?? process.env.REACT_APP_DEV_AUTH_TOKEN
  return token
}

export const saveToken = (token?: string) => {
  if (!token)
    localStorage.removeItem('token')
  else
    localStorage.setItem('token', token)
}

export const getCurrentUser = () => {
  if (config.shouldSkipAuth)
    return 'Guest'

  const token = getToken()
  console.log(token)
  const payload = token?.split('.')[1]
  console.log(payload)
  if (!payload) return undefined

  const json = atob(payload)
  const { user } = JSON.parse(json)
  return user
}

export const shouldSignIn = () => {
  if (config.shouldSkipAuth)
    return false

  const token = getToken()
  // check if token is expired
  if (!token) return true

  try {
    const payload = token.split('.')[1]
    const json = atob(payload)
    const { exp } = JSON.parse(json)
    return Date.now() > exp * 1000
  } catch (error) {
    return true
  }
}